@import "../../styles/sizes";
@import "../../styles/colors";
@import "../../styles/default.sizes.module";

$marginBetweenElements: 4px;

.footer-component {
  flex: 0 1 auto;
  .footer-component-inner {
    width: 100%;
    height: $footerHeight;
    margin: 0 !important;

    .fourteen.wide.column.font-bold.footer-component-texts {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 !important;

      .footer-component-left-text {
        margin-right: $marginBetweenElements;
      }

      .footer-component-links {
        color: $white-color;
        text-align: center;

        .footer-component-link {
          margin-left: $marginBetweenElements;
          margin-right: $marginBetweenElements;

          &:hover {
            cursor: pointer;
            color: darken($white-color, 10);
          }
        }
      }
    }
  }
}

@media (max-width: $mobile-width) {
  .footer-component {
    .footer-component-inner {
      .fourteen.wide.column.font-bold.footer-component-texts {
        flex-direction: column;
        justify-content: center;

        .footer-component-separator {
          display: none;
        }
      }
    }
  }
}
