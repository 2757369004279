@import "../../styles/colors";

.menu-item-dropdown-component {
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .menu-dropdown-item {
    width: 100%;
    height: 100%;

    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;

    > * {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .menu-item-dropdown-icon {
      font-size: 15px;
      margin-left: 15px;
      margin-right: 10px;
      line-height: 20px;

      vertical-align: middle;
    }
  }

  // The panel.
  .menu-item-dropdown-component-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;

    border-top: 2px solid;

    z-index: 2;

    width: auto;

    box-shadow: 2px 2px 1px $grey-color;

    border-bottom: 1px solid rgba(150, 150, 150, 0.1);

    // Item in the list.
    .menu-item-dropdown-component-choice {
      background-color: white;

      &.menu-item-dropdown-component-choice-item {
        border-left: 1px solid rgba(150, 150, 150, 0.1);
        border-right: 1px solid rgba(150, 150, 150, 0.1);

        > * {
          padding: 15px 20px;
          justify-content: flex-start;
          text-align: left;
          white-space: nowrap;

          &:hover {
            background-color: lighten($grey-color, 5);
          }
        }
      }

      .menu-item-dropdown-component-choice-item:last-of-type {
        border-bottom: 1px solid rgba(150, 150, 150, 0.1);
      }

      &.menu-item-dropdown-component-choice-wording + .menu-item-dropdown-component-choice-item {
        border-top: 0;
      }
    }
  }
}
