@import "../../styles/colors";

.menu-item {
  font-size: 10pt;
  text-transform: uppercase;
  color: $black-color;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 100%;
  height: 100%;

  &:hover {
    cursor: pointer;
  }
}
