@import "../../styles/colors";

.menu-dropdown-component {
  height: 100%;

  .menu-dropdown-item {
    height: 100%;

    .menu-dropdown-icon {
      font-size: 15px;
      margin-left: 5px;
      vertical-align: middle;
    }
  }

  // List content.
  .menu-dropdown-component-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;

    border-top: 2px solid;

    z-index: 2;

    border-bottom: 1px solid rgba(150, 150, 150, 0.1);

    box-shadow: 2px 2px 1px $grey-color;

    // Panel is align at the left.
    &.panel-left {
      right: 0;
      left: auto;
    }

    // Item in the list.
    .menu-dropdown-component-choice {
      width: 100%;
      height: 100%;

      &.menu-dropdown-component-choice-item {
        border-left: 1px solid rgba(150, 150, 150, 0.1);
        border-right: 1px solid rgba(150, 150, 150, 0.1);

        > * {
          padding: 15px 20px;
          justify-content: flex-start;
          text-align: left;
          white-space: nowrap;

          &:hover {
            background-color: lighten($grey-color, 5);
          }
        }
      }

      &.menu-dropdown-component-choice-dropdown {
        > * {
          padding: 0;
          > * {
            > * {
              &:hover {
                cursor: default;
              }
            }
          }
        }
      }

      &.menu-dropdown-component-choice-wording + .menu-dropdown-component-choice-item {
        border-top: 0;
      }
    }
  }
}
