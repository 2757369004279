@import "../../styles/colors";
@import "../../styles/sizes";

.mobile-menu-item-dropdown {
  height: 100%;
  width: 100%;

  // Menu header.
  .mobile-menu-item-dropdown-item {
    min-height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;

    .mobile-menu-item-dropdown-item-content {
      width: 100%;
      min-height: 50px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      // Arrow.
      .mobile-menu-item-dropdown-icon {
        font-size: 15px;
        margin-left: 5px;
        vertical-align: middle;
        margin-right: 20px;

        &.reverse {
          transform: rotate(180deg);
        }
      }
    }
  }

  // Menu content.
  .mobile-menu-item-dropdown-panel {
    display: flex;
    flex-direction: column;
    width: 100%;

    // One item of the list.
    .mobile-menu-item-dropdown-choice {
      width: 100%;

      > * {
        width: 100%;
        min-height: $menuItemHeight - 10px;

        padding-left: 20px;

        font-size: 8pt;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:hover {
          background-color: lighten($grey-color, 5);
        }
      }
    }
  }
}
