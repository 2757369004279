@import "../../styles/colors";
.ui.button {
  &.button-component {
    color: white;
    font-size: 12pt;
    text-transform: uppercase;
    border-radius: 0;
    background-color: $success-color;

    margin: 0;

    &.button-component-success:not(.basic) {
      background-color: $success-color;
    }

    &.button-component-danger:not(.basic) {
      background-color: $danger-color;
    }

    &.button-component-grey:not(.basic) {
      background-color: $grey-color;
      color: $black-color;
    }

    &.button-component-warn:not(.basic) {
      background-color: $warn-color;
    }

    &.button-component-secondary:not(.basic) {
      background-color: white;
      color: $success-color;
      border: 2px solid;
      border-color: $success-color !important;
    }
  }

  &:hover {
    opacity: 0.97;

    &.button-component {
      color: #fcfcfc;
      box-shadow: 1px 2px 1px #bbbbbb;
      background-color: darken($success-color, 5%);

      &.button-component-success:not(.basic) {
        background-color: darken($success-color, 5%);
      }

      &.button-component-danger:not(.basic) {
        background-color: darken($danger-color, 5%);
      }

      &.button-component-grey:not(.basic) {
        background-color: darken($grey-color, 5%);
        color: $black-color;
      }

      &.button-component-warn:not(.basic) {
        background-color: $warn-color;
      }

      &.button-component-secondary:not(.basic) {
        background-color: darken(white, 5%);
      }
    }
  }

  &:active {
    transform: translateY(1px);
  }

  &:focus {
    &.button-component {
      color: white;
      background-color: $success-color;

      &.button-component-success:not(.basic) {
        background-color: $success-color;
      }

      &.button-component-danger:not(.basic) {
        background-color: $danger-color;
      }

      &.button-component-grey:not(.basic) {
        background-color: $grey-color;
        color: $black-color;
      }

      &.button-component-warn:not(.basic) {
        background-color: $warn-color;
      }
    }
  }

  &.basic.positive {
    &.button-component {
      color: $success-color !important;
      box-shadow: 0 0 0 1px $success-color inset !important;
      text-transform: uppercase;
    }
  }
}

.ui.inverted.dimmer > .content,
.ui.inverted.dimmer > .content > * {
  color: white !important;
}

.ui.active.button.button-component-success.button-component {
  color: white !important;
}
