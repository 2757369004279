// ## Tokens de couleurs ##
// ## Article expliquant le principe des design tokens ##
// ## www.thecacatoestheory.com/blog/design-token ##

// ## Tokens primitifs ##
@import "./palette-colors.module.scss";

// ## Tokens sémantiques ##

// Background
$background-color: $white;

// Texts
$field-label-color: $neutral-800;
$text-link-color: $light-blue-600;
$text-link-active-color: $light-blue-800;
$text-link-disabled-color: $neutral-700;

// Forms
$search-form-background-color: $neutral-100;

// ## Tokens composants ##

// Header
$header-background-color: $light-blue-400;
$header-text-color: $white;
$header-login-background-color: $white;
$header-login-text-color: $dark-blue-800;

// Footer
$footer-background-color: $neutral-200;
$footer-text-color: $neutral-700;

// Side menu
$side-menu-text-color: $dark-blue-850;
$side-menu-logo-background-color: $dark-blue-50;
$side-menu-background-color: $dark-blue-50;

// TODO Anciens tokens utilisés dans shared-components & ebs, à supprimer au fur et à mesure
$main-color: $light-blue-50;
$main-light-color: $neutral-200;
$neutral-main-background-color: $neutral-50;
$secondary-color: $dark-blue-800;
$secondary-light-color: $dark-blue-50;

:export {
  backgroundColor: $background-color;
  fieldLabelColor: $field-label-color;
  footerBackgroundColor: $footer-background-color;
  footerTextColor: $footer-text-color;
  headerBackgroundColor: $header-background-color;
  headerLoginBackgroundColor: $header-login-background-color;
  headerLoginTextColor: $header-login-text-color;
  headerTextColor: $header-text-color;
  searchFormBackgroundColor: $search-form-background-color;
  sideMenuBackgroundColor: $side-menu-background-color;
  sideMenuLogoBackgroundColor: $side-menu-logo-background-color;
  sideMenuTextColor: $side-menu-text-color;
}
