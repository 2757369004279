// TODO supprimer après transfert progressif vers shared-components/styles/default.sizes.module

// Top menu height.
$menuTopHeight: 30px;
$menuTopHeightSocialReasonMobile: 70px;
// Menu height.
$menuHeight: 90px;
$menuItemHeight: 50px;
// Footer height.
$footerHeight: 80px;

// Size for round icons.
$outerCircleRadius: 71px;
$innerCircleRadius: 57px;
$iconCircleSize: 22px;

// Breadcrumb bar height.
$breadcrumbBarHeight: 70px;
