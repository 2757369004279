@import "./_colors";

//* TODO : A supprimer quand la nouvelle charte graphique sera intégrée sur tous les espaces

body {
  // General text color.
  color: $black-color;
  font-family: "Lato", serif;

  // Fonts.
  .font-bold {
    font-weight: bold;
  }

  // Headings.
  h1 {
    font-weight: bold;
    font-size: 18pt;
  }
  h2 {
    font-weight: bold;
    font-size: 14pt;
  }
  h3 {
    font-weight: bold;
    font-size: 13pt;
    text-transform: uppercase;
  }
  h4 {
    font-size: 14pt;
  }
  h5 {
    font-size: 12pt;
  }
  h6 {
    font-size: 10pt;
  }
}
