// ## Tokens d'ombres ##
// ## Article expliquant le principe des design tokens ##
// ## www.thecacatoestheory.com/blog/design-token ##

// ## Tokens primitifs ##
$shadow-none: none;
$shadow-s: 0px 1px 2px 0px rgba(0, 24, 66, 0.1);
$shadow-m: 0px 2px 10px 0px #3333331a;
$shadow-l: 0px 12px 24px 0px rgba(0, 24, 66, 0.1);
$shadow-l-above: 0px -4px 16px 0px rgba(0, 24, 66, 0.1);

// ## Tokens sémantiques ##
$drop-shadow: 2px 0px 20px 0px #0000001a;

// ## Tokens composants ##
$shadow-m-modal: inset 0px 0px 8px 0px rgba(0, 24, 66, 0.1);
$shadow-s-button: 0px 2px 4px 0px rgba(27, 58, 102, 0.5);
$shadow-m-button: 0px 2px 8px 0px rgba(51, 51, 51, 0.1);

$shadow-header: 0px 2px 16px 0px #0000001a;

:export {
  shadowS: $shadow-s;
  shadowM: $shadow-m;
  shadowL: $shadow-l;
  shadowHeader: $shadow-header;
}
