@import "../../styles/sizes";

$textColor: white;

.menu-top-component {
  flex: 0 1 auto;
  .menu-top-inner {
    margin: 0 !important;
    width: 100%;
    height: $menuTopHeight;
    .menu-top-content {
      color: $textColor;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0;
      justify-content: flex-end;
      width: 87.5%;

      .menu-top-content-right {
        .menu-top-content-right-element {
          display: flex;
          flex-direction: row;
          align-items: baseline;
          white-space: nowrap;

          div:not(:last-child) {
            margin-right: 30px;
            overflow: hidden;
          }

          .menu-top-link:not(.no-underline) {
            text-decoration: underline;
          }

          .menu-top-link:hover:not(.no-underline) {
            color: darken($textColor, 5);
            cursor: pointer;
          }

          .menu-top-content-right-user {
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .menu-top-content-right-icon {
            font-size: 15px;
            margin-right: 8px;
            text-decoration: none;
          }
        }
      }
    }

    .menu-top-content-left {
      .menu-top-content-left-icon {
        font-size: 15px;
        margin-right: 8px;
      }
    }

    .menu-top-content-with-content-on-left {
      justify-content: space-between;
    }

    .menu-top-content-left-icon {
      font-size: 15px;
      margin-right: 8px;
    }

    .menu-top-link:not(.no-underline) {
      text-decoration: underline;
    }

    .menu-top-link:hover:not(.no-underline) {
      color: darken($textColor, 5);
      cursor: pointer;
    }
  }
}

// Part for top with content on left in responsive mode
@media (max-width: 870px) {
  .menu-top-component-with-content-on-left {
    .menu-top-inner {
      height: $menuTopHeightSocialReasonMobile;
      .menu-top-content {
        flex-direction: column;
        align-items: baseline;
        margin-top: 5px;
        margin-bottom: 5px;
        .menu-top-content-right {
          .menu-top-content-right-element {
            flex-direction: column;
          }
        }
      }
    }
  }
}
