@import "../../styles/default.colors.module";
@import "../../styles/default.shadows.module";

.button.primary {
  font-weight: 700 !important;
  background-color: $yellow-400 !important;
  color: $dark-blue-800 !important;
  border-radius: 10rem;
  box-shadow: $shadow-none !important;
  font-size: 1rem;
  line-height: 1rem;
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.button.primary {
  .icon {
    font-size: 1.25rem;
  }

  .left {
    margin-right: 0.5rem !important;
  }

  .right {
    margin-left: 0.5rem !important;
  }
}

.button.primary.small {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  line-height: 1.3125rem !important;
  padding: 0.5rem 1.5rem !important;
}

.button.primary:focus-visible {
  border: 2px solid $dark-blue-800 !important;
}

.button.primary.disabled {
  background-color: $neutral-200 !important;
  color: $neutral-650 !important;
  opacity: 1 !important;
}

.button.primary.loading.disabled {
  background-color: $yellow-300 !important;
}

// On spécifie un comportement au survol seulement si le device le permet
@media (hover: hover) {
  .button.primary:hover {
    background-color: $yellow-500 !important;
    box-shadow: $shadow-s-button !important;

    .button-primary-children {
      text-decoration-line: underline;
      text-underline-offset: 0.25rem;
    }
  }
}
