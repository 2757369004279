@import "../../styles/sizes";

.mobile-menu-component {
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);

  // Menu header.
  .mobile-menu-component-responsive-header {
    height: $menuHeight;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding-right: 8%;
    padding-left: 8%;

    .mobile-menu-component-div {
      justify-content: left !important;
    }

    // Logo.
    .mobile-menu-component-div-logo {
      height: $menuHeight - 30px;
      width: auto;
      padding-top: 5px;
      padding-bottom: 5px;

      &:hover {
        cursor: pointer;
      }
    }
    // Title
    .mobile-menu-component-title {
      font-size: 1.2em;
      text-align: center;
    }
  }

  .mobile-menu-component-responsive-header > * {
    flex: 1;
    display: flex;
  }

  .mobile-menu-component-responsive-header :nth-child(2) {
    justify-content: center;
  }

  .mobile-menu-component-responsive-header :nth-child(3) {
    justify-content: flex-end;
  }

  // Content of the menu.
  .mobile-menu-component-responsive-inner {
    > * {
      min-height: $menuItemHeight;
      border-top: 1px solid rgba(34, 36, 38, 0.15);

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      padding-left: 20px;

      > * {
        justify-content: flex-start;
        min-height: $menuItemHeight;
      }
    }
  }
}
