@import "../../styles/sizes";

$logoVerticalPaddingSize: 10px;

.normal-menu-component {
  border-bottom: 2px solid rgba(34, 36, 38, 0.15);

  .normal-menu-component-grid {
    height: $menuHeight;

    // Remove default margin of the grid.
    margin: 0 !important;

    // Remove default padding of the grid.
    > * {
      padding: 0 !important;
    }

    .normal-menu-component-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: $menuHeight;

      // Right part of the menu, with item choices.
      .normal-menu-component-inner-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;

        > * {
          height: 100%;
          padding-left: 22px;
          padding-right: 22px;

          @media (max-width: 1100px) {
            padding-left: 20px;
            padding-right: 20px;
          }

          @media (max-width: 1000px) {
            padding-left: 18px;
            padding-right: 18px;
          }

          * {
            @media (max-width: 1100px) {
              font-size: 9pt;
            }
          }
        }
      }
    }

    .normal-menu-component-inner > * {
      flex: 1 1;
      display: flex;
    }

    .normal-menu-component-inner :nth-child(2) {
      flex: 0 auto;
      justify-content: center;
    }

    .normal-menu-component-inner :nth-child(3) {
      justify-content: flex-end;
    }
  }

  .normal-menu-component-div-logo {
    height: $menuHeight - $logoVerticalPaddingSize;
    width: auto;
    padding-top: 5px;
    padding-bottom: 5px;

    &:hover {
      cursor: pointer;
    }
  }
}
