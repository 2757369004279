$hamburgerButtonSize: 35px;

.hamburger-button-component {
  border-radius: 15%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: $hamburgerButtonSize;
  height: $hamburgerButtonSize;

  .hamburger-button-component-icon {
    color: white;
    margin: 0 !important;
  }

  &:hover {
    opacity: 0.9;
    cursor: pointer;
    box-shadow: 1px 1px 1px #bbbbbb;
  }
}
