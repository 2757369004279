// Tailles de bordures arrondies du design system
// Les tailles en px correspondent à la configuration par defaut de 1rem = 16px

$radius-none: 0rem; // 0px
$radius-xs: 0.125rem; // 2px
$radius-s: 0.25rem; // 4px
$radius-m: 0.5rem; // 8px
$radius-ml: 0.75rem; // 12px
$radius-l: 1rem; // 16px
$radius-xl: 1.5rem; // 24px
$radius-full: 999px;

:export {
  radiusNone: $radius-none;
  radiusXS: $radius-xs;
  radiusS: $radius-s;
  radiusM: $radius-m;
  radiusML: $radius-ml;
  radiusL: $radius-l;
  radiusXL: $radius-xl;
  radiusFull: $radius-full;
}
