// Tailles d'espacement du design system
// Les tailles en px correspondent à la configuration par defaut de 1rem = 16px

$spacing-00: 0rem; // 0px
$spacing-01: 0.125rem; // 2px
$spacing-02: 0.25rem; // 4px
$spacing-03: 0.5rem; // 8px
$spacing-04: 0.75rem; // 12px
$spacing-05: 1rem; // 16px
$spacing-06: 1.5rem; // 24px
$spacing-07: 2rem; // 32px
$spacing-08: 2.5rem; // 40px
$spacing-09: 3rem; // 48px
$spacing-10: 3.5rem; // 56px
$spacing-11: 4rem; // 64px
$spacing-12: 4.5rem; // 72px
$spacing-13: 5rem; // 80px

:export {
  spacing00: $spacing-00;
  spacing01: $spacing-01;
  spacing02: $spacing-02;
  spacing03: $spacing-03;
  spacing04: $spacing-04;
  spacing05: $spacing-05;
  spacing06: $spacing-06;
  spacing07: $spacing-07;
  spacing08: $spacing-08;
  spacing09: $spacing-09;
  spacing10: $spacing-10;
  spacing11: $spacing-11;
  spacing12: $spacing-12;
  spacing13: $spacing-13;
}
