.cookies-modal {
  .cookies-modal-title {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
  }
  .cookies-modal-paragraph {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
    text-align: justify;
  }
}

.ui.page.dimmer.modals {
  background-color: rgba(0, 0, 0, 0.55) !important;
}

.cookies-modal-button {
  margin-bottom: 10px;
}

.cookies-modal-button-opacity {
  opacity: 0.4;
}

.cookies-modal-row-button {
  width: 116px;
  @media (max-width: 815px) {
    width: 100%;
  }
}

.cookies-modal-row-buttons {
  margin-top: 35px !important;
  @media (max-width: 815px) {
    margin-top: 0px !important;
    margin-bottom: 35px !important;
  }
}

.cookies-modal-custom {
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
  color: gray;
}

.cookies-modal-row {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.cookies-modal-row-column {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

h5 {
  margin-bottom: 5px !important;
}

.cookies-modal-consent {
  text-decoration: underline;
  margin-top: 20px;
  text-align: end;
  cursor: pointer;
  font-size: 14px;
  color: gray;
}

.cookies-modal-header {
  margin-top: 10px;
}
