// ## Palette de couleurs de la charte graphique Domiserve ##

$black: #000000;
$white: #ffffff;

$light-blue-50: #f5fafd;
$light-blue-100: #ecf5fc;
$light-blue-200: #d1edff;
$light-blue-300: #99d7ff;
$light-blue-400: #66b2e4;
$light-blue-500: #298cce;
$light-blue-600: #0677c1;
$light-blue-700: #1b6fae;
$light-blue-800: #17588d;
$light-blue-900: #0f3b58;

$dark-blue-50: #f4f7fc;
$dark-blue-100: #e0e8f7;
$dark-blue-200: #ccdaf1;
$dark-blue-300: #aac2e6;
$dark-blue-400: #81a2d9;
$dark-blue-800: #183a68;
$dark-blue-850: #002c68;
$dark-blue-900: #10233d;

$pink-700: #e5007a;
$pink-800: #b0045d;

$neutral-50: #fafbfb;
$neutral-100: #f1f2f5;
$neutral-200: #dddfe3;
$neutral-300: #d0d4d9;
$neutral-400: #aab0b9;
$neutral-500: #8f95a2;
$neutral-600: #717b89;
$neutral-650: #606a78;
$neutral-700: #4b525c;
$neutral-800: #333333;
$neutral-900: #121212;

$yellow-300: #ffe275;
$yellow-400: #ffcb05;
$yellow-500: #efb403;
$yellow-600: #ce8b00;

$success-50: #e8faf6;
$success-500: #179777;
$success-800: #0c5e49;

$warning-50: #fff4e9;
$warning-500: #fe9526;
$warning-700: #b1590d;

$error-50: #ffeceb;
$error-500: #fd3d39;
$error-700: #c00602;

:export {
  black: $black;
  white: $white;
  darkBlue100: $dark-blue-100;
  darkBlue200: $dark-blue-200;
  darkBlue300: $dark-blue-300;
  darkBlue400: $dark-blue-400;
  darkBlue50: $dark-blue-50;
  darkBlue800: $dark-blue-800;
  darkBlue850: $dark-blue-850;
  darkBlue900: $dark-blue-900;
  lightBlue100: $light-blue-100;
  lightBlue200: $light-blue-200;
  lightBlue300: $light-blue-300;
  lightBlue400: $light-blue-400;
  lightBlue50: $light-blue-50;
  lightBlue500: $light-blue-500;
  lightBlue600: $light-blue-600;
  lightBlue700: $light-blue-700;
  lightBlue800: $light-blue-800;
  neutral100: $neutral-100;
  neutral200: $neutral-200;
  neutral300: $neutral-300;
  neutral400: $neutral-400;
  neutral50: $neutral-50;
  neutral500: $neutral-500;
  neutral600: $neutral-600;
  neutral650: $neutral-650;
  neutral700: $neutral-700;
  neutral800: $neutral-800;
  neutral900: $neutral-900;
  pink700: $pink-700;
  pink800: $pink-800;
  yellow300: $yellow-300;
  yellow400: $yellow-400;
  yellow500: $yellow-500;
  yellow600: $yellow-600;
  success50: $success-50;
  success500: $success-500;
  success800: $success-800;
  warning50: $warning-50;
  warning500: $warning-500;
  warning700: $warning-700;
  error50: $error-50;
  error500: $error-500;
  error700: $error-700;
}
