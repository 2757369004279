// ## Tokens de taille et d'espacement ##
// ## Article expliquant le principe des design tokens ##
// ## www.thecacatoestheory.com/blog/design-token ##

// ## Tokens primitifs ##
@import "./spacing.module.scss";
@import "./radius.module.scss";

// ## Tokens sémantiques ##

// Responsive
$small-mobile-width: 480px;
$mobile-width: 768px;
$small-screen-width: 1024px;
$medium-screen-width: 1280px;

// Font
$desktop-font-size: 1rem; // 16px
$mobile-font-size: 0.875rem; // 14px

// Form
$login-form-width: 23rem;

// ## Tokens composants ##

// Header
$header-height: 4rem;
$header-mobile-height: 3.43rem;

// Footer
$footer-height: 2.5rem;

// Menu navigation (SideMenu)
$menu-navigation-width: 17rem;

// Menu mobile EBS
$menu-navigation-mobile-height: 4rem;

// Menu choix profil
$menu-profiles-mobile-height: 5rem;
$menu-profiles-width: 5.25rem;

// Preview
$preview-width: 28rem;

:export {
  footerHeight: $footer-height;
  headerHeight: $header-height;
  headerMobileHeight: $header-mobile-height;
  menuNavigationWidth: $menu-navigation-width;
  mobileFontSize: $mobile-font-size;
}
