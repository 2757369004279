.error-component {
  .outer {
    display: table;
    position: absolute;
    height: 100%;
    width: 100%;

    .inner {
      height: 100vh;
      width: 100vw;
      display: table-cell;
      vertical-align: middle;

      .content {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
          width: 200px;
          height: auto;
          margin-bottom: 15px;
        }

        .oops {
          font-size: 30px;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .text {
          text-align: center;
          line-height: 30px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
