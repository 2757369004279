@import "../../styles/default.colors.module";
@import "../../styles/default.shadows.module";

.button.basic {
  font-weight: 700 !important;
  background-color: unset !important;
  color: $dark-blue-800 !important;
  border-radius: 10rem !important;
  outline: 2px solid $yellow-400 !important;
  outline-offset: -2px;
  box-shadow: $shadow-none !important;
  font-size: 1rem;
  line-height: 1rem;
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;

  &.red {
    outline: 0.125rem solid $error-500 !important;
  }

  &.blue {
    outline: 0.125rem solid $dark-blue-800 !important;
  }
}

// TODO: supprimer après remplacement par la classe "blue"
.button.basic.button-blue-color {
  border: 0.125rem solid $dark-blue-800 !important;
  outline: unset !important;
  background-color: $white !important;
}

.button.basic {
  .icon {
    font-size: 1.25rem;
  }

  .left {
    margin-right: 0.5rem !important;
  }

  .right {
    margin-left: 0.5rem !important;
  }
}

.button.basic.small {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  line-height: 1.3125rem !important;
  padding: 0.5rem 1.5rem !important;
}

.button.basic:focus-visible {
  border: 2px solid $dark-blue-800 !important;
}

.button.basic.disabled {
  background-color: $neutral-100 !important;
  color: $neutral-650 !important;
  border: 2px solid $neutral-300 !important;
  outline: unset !important;

  opacity: 1 !important;
}

.button.basic.loading.disabled {
  background-color: none !important;
}

// On spécifie un comportement au survol seulement si le device le permet
@media (hover: hover) {
  .button.basic:hover {
    box-shadow: $shadow-s-button !important;
    background-color: none !important;

    .button-basic-children {
      text-decoration-line: underline;
      text-underline-offset: 0.25rem;
    }
  }
}

.ui.button > i.icon {
  font-size: 1rem !important;
}
.ui.button > i.icon.left {
  margin: 0 0.5rem 0 0 !important;
}
.ui.button > i.icon.right {
  margin: 0 0 0 0.5rem !important;
}
