.fade-transition-enter {
  opacity: 0.01;
}

.fade-transition-enter.fade-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-transition-exit {
  opacity: 1;
}

.fade-transition-exit.fade-transition-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}